<template>
	<v-app style="background-color: #1b1b1b;">
		<v-container class="d-flex flex-column align-center pa-0">
			<v-responsive width="1024" class="mx-auto" style="position: absolute; z-index: 1;">
				<v-btn dark to="/" text>
					<span class="mr-2">主页</span>
					<v-icon>mdi-open-in-new</v-icon>
				</v-btn>
				<v-btn dark to="/about" text>
					<span class="mr-2">中古相机</span>
					<v-icon>mdi-open-in-new</v-icon>
				</v-btn>
			</v-responsive>
			<v-main>
				<router-view />
			</v-main>
			<v-responsive class="mx-auto my-6 text-center white--text text-caption" max-width="1024" width="100%">@2022 Liu's Collection</v-responsive>
		</v-container>
	</v-app>
</template>

<script>
export default {
	name: 'App',

	data: () => ({
		//
	}),
}
</script>
