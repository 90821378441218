<template>
	<v-container class="pa-0">
		<v-card class="mx-auto" max-width="1024" dark>
			<v-img class="shrink" contain src="/home.jpg" transition="fade-transition" width="1024"></v-img>
			<v-card-text class="text-center">
				本人玩胶片相机十几年，谈不上资深，但也算半个行家，平时对中古相机情有独钟。<br>
				这里推荐的都是我个人认为性价比较高的胶片相机，适合入门不走弯路，机器分为胶卷相机和一次成像，价位高低都有，可根据自身情况选购。<br>
				这些机器都是我个人购入收藏，并且深度使用过，希望可以给还在犹豫的你一些帮助。<br>
				很多网红机器排除不在此次推荐范围内，好了，先从大家最熟悉的尼康佳能开始。
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>

export default {
	name: 'HomeView',
	data: () => ({
		//
	}),
}
</script>
