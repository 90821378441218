import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
	"theme": {
		"dark": false,
		"default": "light",
		"disable": false,
		"themes": {
			"light": {
				"primary": "#cc9966",
				"secondary": "#29B6F6",
				"accent": "#FFB300",

				"error": "#f44336",
				"warning": "#29B6F6",
				"info": "#8bc34a",
				"success": "#4caf50"
			},
			"dark": {
				"primary": "#1E88E5",
				"secondary": "#33FF99",
				"accent": "#FFB300",

				"error": "#FF5252",
				"info": "#2196F3",
				"success": "#4CAF50",
				"warning": "#FB8C00"
			}
		}
	}
})
